@import '../../../styles/variables.less';

.page-header {
    background-color: @white-color;
    box-shadow: 0 1px 6px 0 rgba(0, 21, 41, 0.04);
    width: 100%;
    padding: 20px;
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &--children{    
        position: absolute;
        bottom: 0;
    }

    h1 {
        color: @dark-blue-color;
        font-weight: 500;
        font-size: 18px;
    }

    &--account-balance-section {
        margin: auto 30px auto 0;
        display: flex;
        align-items: center;
    }

    &--account-balance-details {
        line-height: normal;

        label {
            color: @dim-grey;
            font-size: 14px;
        }

        h4 {
            margin: 0;
            font-size: 18px;
            font-weight: 500;
        }
    }

    &--account-balance-icon {
        height: 40px;
        margin-right: 10px;
    }
}