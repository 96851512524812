@import '../../styles/variables.less';

.settings-tabs {
    width: 100%;
    padding: 20px;
    display: flex;

    &--card {
        background: @white-color;
        min-width: 300px;
        border-radius: 12px;
        position: relative;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 20px;
        }

        h4 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0;
        }
    }

    &--icon-wrap {
        margin-right: 20px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.blue {
            background: rgb(213, 241, 255);

            .icon {
                color: rgb(92, 202, 255);
                font-size: 20px;
            }
        }

        &.yellow {
            background: rgb(255, 233, 168);

            .icon {
                color: rgb(249, 187, 7);
                font-size: 20px;
            }
        }
    }
}