@import '../../../styles/variables.less';

.expiry-table {
    background: #fff;
    border-radius: 12px;
    &--filters {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        label {
            font-size: 16px;
            margin-right: 11px;
        }

        &-search {
            display: flex;
            align-items: center;

            .ant-input.ant-input-lg {
                background: @white-color;
                height: 40px;
                font-size: 16px;
            }

            .ant-input-search .ant-input:hover,
            .ant-input-search .ant-input:focus {
                box-shadow: none;
            }

            .ant-btn {
                box-shadow: none !important;
                border-left: 0;
            }
        }
    }

    &--table {
        padding: 0 20px 20px;

        .ant-table-thead>tr>th {
            background: @ghost-white-color;
            font-size: 15px;
            font-weight: normal;
            padding: 20px 16px;
            border-bottom-width: 0;
        }

        .ant-table-container table>thead>tr:first-child th:first-child {
            border-top-left-radius: 0;
            padding-left: 35px;
        }

        .ant-table-tbody>tr>td:first-child {
            padding-left: 35px;
        }

        .ant-table-container table>thead>tr:first-child th:last-child {
            border-top-right-radius: 0;
            padding-right: 35px;
        }

        .ant-table-tbody>tr>td:last-child {
            padding-right: 35px;
        }

        .ant-table-cell {
            font-weight: 300;
            font-size: 15px;
            line-height: normal;
        }

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            width: 0;
        }
    }

    &--table-cell-status{
        font-weight: 300;
        &.success{
            color: @green-color;
        }

        &.failed{
            color: @red-color;
        }
    }

    &--id-cell {
        font-weight: normal;
        color: @primary-color;
    }
}