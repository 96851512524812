.expiry-reminder {
    width: 100%;
    max-width: 800px;
    padding: 20px 22px;
    margin-right: auto;

    &--container.ant-card {
        background: #ffffff;
        border-radius: 12px;

        .ant-card-body {
            padding: 30px 30px 40px;
        }

    }
}