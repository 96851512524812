// common antd customisations

@import '~antd/dist/antd.less';
@import './variables.less';



.ant-btn {
    font-size: @body-font-size;

    &:not(.ant-btn-link) {
        box-shadow: @button-shadow;
    }
}

.ant-input,
.ant-input-password {
    background-color: @ghost-white-color-2;
    font-size: @body-font-size;
    border-width: 0.5px;

    &.ant-input-lg.custom-input,
    &.ant-input-password-large.custom-input {
        min-height: 40px;
    }
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: @ghost-white-color-2;
}

.ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
}

.ant-form-item-label>label {
    font-size: 12px;
    padding-bottom: 4px;
}

.ant-form-item-explain-error {
    font-size: 12px;
}

.ant-modal-confirm-body > .anticon {
    margin-right: 11px;
    font-size: 16px;
    margin-top: 3px;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content{
    margin-left: 28px;
}

.ant-modal-confirm-btns .ant-btn{
    min-width: 80px;
}

.ant-message-notice-content{
    background-color: @eerie-black-color;
    color: @white-color;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-weight: 500;
}