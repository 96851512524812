@import "../../../styles/variables.less";

.mobile-error-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 99999;
    overflow-y: auto;

    @media screen and (max-width: 1023px) {
        display: block;
    }


    &--info {
        text-align: center;
        line-height: normal;
        font-size: 12px;
        margin-bottom: 60px;

        b {
            font-weight: 500;
        }
    }

    &--icon {
        max-width: 100%;
        max-height: 155px;
        display: block;
        margin: 0 auto 56.5px;
    }

    &--continue-btn {
        margin: auto;
        font-size: 11px;
        display: flex;
        align-items: center;

        &.ant-btn>span+.anticon {
            margin-left: 4px;
            line-height: 0;
            font-size: 11px;
        }
    }

    .ant-layout{
        min-height: 100%;
    }

    .ant-layout-header {
        height: 44px;
        background-color: @white-color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid @bright-gray;
    }

    .ant-layout-content {
        min-height: calc(100vh - 92px);
    }

    .ant-layout-footer {
        height: 48px;
        background-color: @white-color;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @gray-color;
        font-size: 12px;
    }

    .ant-card {
        margin: 16px 20px;
        border-radius: 12px;
        box-shadow: 0 30px 60px 0 rgba(92, 99, 105, 0.15);
        border: solid 0.5px rgba(92, 99, 105, 0.15);
    }

    .ant-card-body {
        padding: 60px 30px;
    }
}