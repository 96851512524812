.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    &-image {
        img {
            object-fit: contain;
            width: 609.5px;
            height: 391.7px;
        }
    }

    &-content {
        text-align: center;

        h3 {
            margin: 0;
            font-size: 16px;
            font-weight: 900;
        }

        p {
            font-size: 13px;
            font-weight: normal;
        }
    }

    &-button {
        display: flex;

        button {
            padding: 0;
            border-width: 0;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }

        img {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }
    }
}