@import './antd.less';
@import './variables.less';

.app-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.dashboard {
        min-height: calc(100vh - 150px);
        justify-content: flex-start;
    }
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.mb-0 {
    margin-bottom: 0 !important;
}


.ant-btn.secondary-btn {
    background-color: @alice-blue-color;
    border-color: @alice-blue-color;
    color: @blue-color;
    box-shadow: none;

    &:hover,
    &:focus {
        background-color: @lavender-color;
        border-color: @lavender-color;
    }
}

.btn-group {
    .ant-btn:not(:last-child) {
        margin-right: 10px;
    }
}

.common-dialog-box {
    min-width: 468px;

    &.ant-modal-confirm.ant-modal-confirm-confirm .anticon.anticon-question-circle {
        color: @primary-color;
    }
}

// login and reset card styles
.public-page-card {
    width: 100%;
    max-width: 510px;
    border-radius: @card-border-radius;
    overflow: hidden;
    box-shadow: @card-shadow;
    margin: 20px 0;

    .ant-form-item {
        margin-bottom: 20px;

        &.ant-form-item-with-help.ant-form-item-has-error {
            margin-bottom: 30px;
        }

    }

    .ant-card-body {
        padding: 0;
    }

    &--logo-container {
        margin-bottom: 30px;
    }

    &--main {
        padding: 27px 30px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-forgot-password {
            font-size: 12px;
        }

        form {
            width: 75%;
        }

        &-logo {
            height: 48px;
            margin-bottom: 30px;
        }
    }
}

.issue-credits-confirmation {
    &--warnings {
        padding-left: 12px;
        max-height: 200px;
        overflow-y: auto;
        margin-top: 5px;

        li {
            font-size: 13px;
            line-height: normal;
            margin-bottom: 5px;

            strong {
                font-weight: 500;
            }
        }
    }
}

.table-pagination-total {
    color: @gray-color;
    margin-right: 10px;
}