@import '../../../styles/variables.less';

.user-popover-menu {
    line-height: normal;
    cursor: pointer;

    .ant-avatar.ant-avatar-circle {
        background-color: @yellow-color;
    }

    .anticon.anticon-down {
        font-size: 9px;
    }

    &--footer {
        padding: 15px;
        display: flex;
        justify-content: center;

        button {
            min-width: 130px;
        }
    }

    &--avatar-bottom {
        span {
            margin-right: 2px;
        }
    }

    &-content {
        padding-top: 1px;
    }

    &--powered-by {
        background-color: @anti-flash-white-color;
        margin: 0 5px;
        border-radius: 6px;
        padding: 6px;
        text-align: center;
        font-size: 12px;

        b {
            font-weight: 500;
        }
    }

    &--user-details {

        padding: 15px;

        &-heading {
            font-weight: 500;
            font-size: 16px;
        }

        &-info {
            display: flex;
            align-items: center;

            .ant-avatar.ant-avatar-circle {
                background-color: @yellow-color;
            }

            &-profile {
                margin-left: 12px;

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 1.4;
                    max-width: 300px;

                    b {
                        font-weight: 500;
                    }

                    i {
                        margin-right: 2px;
                    }

                    em {
                        font-style: normal;
                        color: @dim-grey;
                    }
                }
            }

        }
    }

    &--avatar-container {
        position: relative;

        .anticon.anticon-check-circle {
            position: absolute;
            bottom: 0;
            right: 0;
            color: @green-color;
            border: 1px solid @white-color;
            border-radius: 50%;
            background-color: @white-color;
        }
    }
}

.ant-popover.user-popover-menu {

    &--overlay {
        .ant-popover-inner-content {

            padding: 5px 0 0 0;
        }

        .ant-divider.ant-divider-horizontal {
            margin: 0;
        }

        .ant-popover-inner {
            border-radius: 12px;
            min-width: 373px;
            min-height: 200px;
        }

        .ant-skeleton-title {
            margin-bottom: 5px;
        }

        .ant-skeleton-paragraph {
            margin-top: 8px;
        }

    }
}