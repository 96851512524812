@import "../../../styles/variables.less";

.floaing-label-select {
    position: relative;

    .ant-select {
        &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #e8e8e8;
        }

        &.ant-select:not(.ant-select-customize-input).ant-select-disabled .ant-select-selector {
            border-bottom-style: dashed;
        }

        &:focus {
            box-shadow: 0px 3px 0 0px rgb(64 113 217 / 20%);

            &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
                box-shadow: 0px 3px 0 0px rgb(255 77 79 / 20%);
                border-right-width: 0;
            }
        }

        &.ant-select-single .ant-select-selector .ant-select-selection-search {
            left: 0;
        }
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: transparent;
    }

    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        height: 34px;
        padding: 0;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: 0px 3px 0 0px rgb(64 113 217 / 20%);
    }

    .ant-select-status-error.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: 0px 3px 0 0px rgb(255 75 77 / 20%);
    }

    .ant-select-selection-item {
        font-size: 14px;
    }

    .ant-select:focus {
        box-shadow: none;
    }

    .label {
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 6px;
        transition: 0.4s ease all;
        color: @dim-black;

        &.as-placeholder {
            line-height: normal;
        }

        &.as-label {
            top: -10px;
            font-size: 12px !important;
            background: white;
            padding: 0 4px;
            margin-left: -4px;
            line-height: normal;
            z-index: 1;
        }

        &.disabled {
            opacity: 0.3;
        }
    }


}