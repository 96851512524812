@import '../../../styles/variables.less';

.init-loader {
    width: 160px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .img-container {
        position: absolute;
        left: 50%;
        bottom: 25px;
        transform: translate(-50%, 0%);
        width: 74.8px;
    }

    .page-loader {
        height: 4px;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: @chinese-silver-color;
        border-radius: 25px;
    
        &::before {
            display: block;
            position: absolute;
            content: "";
            left: -200px;
            width: 200px;
            height: 4px;
            background-color: @primary-color;
            animation: loading 2s linear infinite;
    
        }
    }
    
    
    @keyframes loading {
        from {
            left: -200px;
            width: 30%;
        }
    
        50% {
            width: 30%;
        }
    
        70% {
            width: 70%;
        }
    
        80% {
            left: 50%;
        }
    
        95% {
            left: 120%;
        }
    
        to {
            left: 100%;
        }
    }
}